
import { reactive, computed } from "vue";
import { utils } from "../utils";
import { useApp } from "./app";
import { useStore } from 'vuex';


export function useEditPage(props, formData, v$, onFormSubmited) {

	const app = useApp();
	const store = useStore();
	const state = reactive({
		id: null,
		loading:false,
		pageReady:false,
		submitted: false,
		saving: false,
		errorMsg: ''
	});

	const apiUrl = computed(() => {
		if(props.id){
			return props.apiPath + '/' + encodeURIComponent(props.id);
		}
		return props.apiPath;
	});

	const currentRecord = computed( {
		get: function () {
			return store.getters[`${props.pageName}/currentRecord`];
		},
		set: function (value) {
			store.commit(`${props.pageName}/setCurrentRecord`, value);
		},
	});

	async function submitForm(){
		state.submitted = true;
		const isFormValid = !v$.value.$invalid;
		if(!isFormValid){
			app.flashMsg("Form is invalid", "Please complete the form", "error");
			return;
		}
		state.saving = true;
		let url = apiUrl.value;
		let id = props.id;
		let data = {id,  url, payload: formData }
		try{
			let response = await store.dispatch(`${props.pageName}/updateRecord`, data);
			state.saving = false;
			state.submitted = false;
			app.closeDialogs();// close page dialog that if opened
			onFormSubmited(response);
		}
		catch(e) {
			state.saving = false;
			app.showPageRequestError(e);
		}
	}



	async function load() {
		state.pageReady = false;
		state.loading = true;
		state.item = null;
		let url = apiUrl.value;
		try{
			await store.dispatch(`${props.pageName}/fetchRecord`, url);
			state.loading = false;
			Object.assign(formData, currentRecord.value); //update form data
			state.pageReady = true;
		}
		catch(e){
			console.error(e);
			state.loading = false;
			app.showPageRequestError(e);
		}
	}

	function getErrorClass(field){
		return {"p-invalid": v$.value[field].$invalid && state.submitted};
	}

	function getFieldError(field){
		const fieldErrors = v$.value[field].$silentErrors;
		if(fieldErrors.length){
			return fieldErrors[0].$message; //return the first error
		}
		return null;
	}

	function isFieldValid(field, index){
		if(index===undefined){
			return v$.value[field].$invalid && state.submitted;	
		}
		else if(v$.value.$each.$response.$errors[index][field].length && state.submitted){
			return true;
		}
		return false;
	}

	const computedProps = {
		apiUrl,
		currentRecord
	}

	const methods = {
		load,
		submitForm,
		getFieldError,
		getErrorClass,
		isFieldValid
	}
	
	return {
		state,
		computedProps,
		methods
	}
}